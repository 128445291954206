<template>
    <div id="home" class="page-wrapper page-home">
        <!-- Hero Projet -->
        <section id="hero-projet" class="hero-projet extends hero-quartier hero-transition-group">
            <div class="inner site-max-width">
                <div class="wrap">
                    <img class="spacer" :src="contentIsLoaded ? content.heroProjet.image.url : ''" :alt="contentIsLoaded ? content.heroProjet.image.titre : ''" />
                    <div class="content">
                        <h3 class="top-label label" data-inview="fadeInUp" data-delay="200">
                            {{ contentIsLoaded ? content.heroProjet.surtitre : '' }}
                        </h3>
                        <h1 class="big-title title" data-inview="fadeInUp" data-delay="1000">
                            <span v-html="contentIsLoaded ? content.heroProjet.titre : '&nbsp;'"></span>
                        </h1>
                        <a
                            href="#content"
                            class="scroll-down"
                            data-inview="fadeInUp"
                            data-delay="1200"
                            @click.prevent="smoothScrollTo('#content')"
                        >
                            <svg
                                height="65"
                                viewBox="0 0 43 89"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M20.5739 85.5268L20.5739 -1.96055e-06L22.4261 -1.79863e-06L22.4261 85.5268L41.7031 65.9109L43 67.2244L21.5 89L-5.72571e-06 67.2244L1.2046 65.9109L20.5739 85.5268Z"
                                    fill="black"
                                />
                            </svg>
                        </a>
                    </div>
                </div>
            </div>
            <div class="img-wrap" data-inview="revealLeft" data-delay="200">
                <img class="main img-right" :src="contentIsLoaded ? content.heroProjet.image.url : ''" :alt="contentIsLoaded ? content.heroProjet.image.titre : ''" />
            </div>
        </section>

        <!-- Infos grid -->
        <section id="content" class="infos" projet-zoom>
            <div class="content site-max-width" v-if="contentIsLoaded">
                <div class="col" v-for="(item, i) in content.stats" :key="i">
                    <p class="regular-text num-text">
                        <span class="big-num" data-inview="fadeInUp" data-delay="200" v-if="item.nombre">{{ item.nombre }}</span>
                        <span data-inview="fadeInUp" data-delay="400" v-if="item.texte"
                            >{{ item.texte }}</span
                        >
                    </p>
                </div>
            </div>

            <div class="grid-images-dynamic">
                <img class="size" :src="contentIsLoaded ? content.grilleImages.image1.url : '/static-imgs/projet-1.jpg'" :alt="contentIsLoaded ? content.grilleImages.image1.titre : ''" />

                <img
                    class="main"
                    :src="contentIsLoaded ? content.grilleImages.image1.url : '/static-imgs/projet-1.jpg'"
                    :alt="contentIsLoaded ? content.grilleImages.image1.titre : ''"
                    projet-zoom-main
                />

                <img
                    class="secondary"
                    :src="contentIsLoaded ? content.grilleImages.image2.url : '/static-imgs/projet-1.jpg'"
                    :alt="contentIsLoaded ? content.grilleImages.image2.titre : ''"
                    projet-zoom-seconday
                />

                <SealDurable class="seal" condo-rotate />
            </div>

            <div class="content site-max-width a-start" v-if="contentIsLoaded">
                <div class="col" v-for="(item, i) in content.titresEtTextes" :key="i">
                    <h2 class="regular-title title" data-inview="fadeInUp" data-delay="200" v-if="item.titre">
                        <span v-html="item.titre"></span>
                    </h2>
                    <h2 class="regular-title title ghost-title" data-inview="fadeInUp" data-delay="200" v-else>
                        <span v-html="content.titresEtTextes[0].titre"></span>
                    </h2>
                    <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                        <span v-html="item.texte"></span>
                    </p>
                </div>
            </div>
        </section>

        <!-- Slider -->
        <section id="slider" class="slider site-max-width">
            <h3 class="top-label label" data-inview="fadeInUp" data-delay="200">
                {{ contentIsLoaded ? content.titreSimple : '' }}
            </h3>

            <div class="carousel carousel-moderne" v-if="contentIsLoaded">
                <div class="carousel-cell" v-for="(item, i) in content.carousel" :key="i">
                    <img :src="item.image.url" :alt="item.image.titre" />
                </div>
            </div>

            <div class="content">
                <div class="col">
                    <h2 class="regular-title title" data-inview="fadeInUp" data-delay="200">
                        <span v-html="contentIsLoaded ? content.entete.titreGauche : '&nbsp;'"></span>
                    </h2>
                </div>
                <div class="col">
                    <p class="regular-text" data-inview="fadeInUp" data-delay="400">
                        <span v-html="contentIsLoaded ? content.entete.texteDroite : '&nbsp;'"></span>
                    </p>
                </div>
            </div>

            <div class="grid-logos" v-if="contentIsLoaded">
                <a
                    :href="item.lien"
                    target="_blank"
                    rel="noopener noreferrer"
                    class="logo"
                    data-inview="fadeInUp"
                    data-delay="200"
                    v-for="(item, i) in content.logos" :key="i"
                >
                    <div class="logo-wrap">
                        <img :src="item.logo.url" :alt="item.logo.titre" />
                    </div>
                    <div class="content">
                        <p class="small-text">{{ item.titre }}</p>
                    </div>
                </a>
            </div>
        </section>

        <!-- Form -->
        <BottomForm :formLink="globalsIsLoaded ? globals.footer.formulaireVariantePageProjet.lienUrl[$route.meta.lang] : ''" :formTitle="$t('Découvrez <br /> les condos')" />
    </div>
</template>

<script>
// @ is an alias to /src
import Flickity from 'flickity'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'

import { initInViewAnimations } from '@/plugins/InViewAnimations'

import { mapState, mapGetters, mapActions } from 'vuex'
import BottomForm from '@/components/BottomForm'
import SealDurable from '@/components/svgs/SealDurable'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(ScrollToPlugin)

export default {
    name: 'Projet',

    data() {
        return {
            slider: null,
        }
    },

    components: {
        BottomForm,
        SealDurable,
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.startHomeHeroAnimations()
                        this.initPageAnims()
                        this.slider = new Flickity(document.querySelector('.carousel-moderne'), {
                            cellAlign: 'center',
                            wrapAround: true,
                            lazyLoad: 1,
                            imagesLoaded: true,
                            prevNextButtons: true,
                            pageDots: false,
                        })

                        setTimeout(() => {
                            initInViewAnimations()
                            document.querySelector('#app').classList.add('is-loaded')
                        }, this.initAnimsDelay1)

                        setTimeout(() => {
                            this.slider.resize()
                        }, 2555)
                    }, 475)

                    setTimeout(() => {
                        document.querySelector('.site-loader').classList.add('is-loaded')
                        document.querySelector('.page-wrapper').classList.add('is-loaded')
                        this.setAnimsDelays(55)
                    }, this.initAnimsDelay2)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded', 'initAnimsDelay1', 'initAnimsDelay2']),
        ...mapGetters(['seo', 'content', 'globals']),
    },

    methods: {
        ...mapActions(['setAnimsDelays']),
        smoothScrollTo(selector) {
            setTimeout(() => {
                gsap.to(window, { duration: 2, scrollTo: selector })
            }, 175)
        },
        initPageAnims() {
            gsap.to('[projet-zoom-main]', {
                scrollTrigger: {
                    trigger: '[projet-zoom]',
                    start: 'top 75%',
                    end: () => '+=75%',
                    scrub: 0.5,
                },
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                ease: 'none',
            })

            gsap.to('[projet-zoom-seconday]', {
                scrollTrigger: {
                    trigger: '[projet-zoom]',
                    start: 'top 75%',
                    end: () => '+=75%',
                    scrub: 0.5,
                },
                y: 0,
                x: 0,
                opacity: 1,
                scale: 1,
                ease: 'none',
            })

            gsap.fromTo(
                '[condo-rotate]',
                {
                    rotate: 0,
                },
                {
                    rotation: 120,
                    duration: 48,
                    repeat: -1,
                    yoyo: true,
                    ease: 'none',
                }
            )
        },
    },
}
</script>
